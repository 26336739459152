<div class="modal-container">
    <div class="modal-header">
        <div class="header-left">
            @if (goBack) {
                <img src="/assets/icons/go-back.svg" (click)="goBackModal()">
            }
            @if (title) {
                <h2>{{ title }}</h2>
            }
            @if (saveImage) {
                <label>Save Image</label>
            }
        </div>
        <img src="/assets/icons/exit-cross.svg" (click)="closeModal()">
    </div>
    <div class="modal-content">
        <ng-content></ng-content>
    </div>
</div>
