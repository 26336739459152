import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: 'app-modal',
    standalone: true,
    imports: [],
    templateUrl: './modal.component.html',
    styleUrl: './modal.component.scss'
  })

  export class ModalComponent {
    
    @Input() title: string = '';
    @Input() goBack: boolean = false;
    @Input() saveImage: boolean = false;
    @Output() goBackEvent = new EventEmitter<void>();

    constructor(
      public dialog: MatDialogRef<ModalComponent>,
    ) {}

    public closeModal(): void {
      this.dialog.close();
    }

    public goBackModal() {
      this.goBackEvent.emit();
    }

  }